import {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {useQuery, QueryFunctionContext} from 'react-query';

import {ListResponse, Upload, User, Vehicle} from 'types';

import api from 'api';
import roles from 'constants/roles';
import states from 'constants/states';

interface Option {
  label: string;
  value: string | number;
}

interface FormValue {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  zipcode: string;
  state: Option;

  username: string;
  password: string;
  password_confirmation: string;

  role: Option;
  vehicle?: Option;

  license_front: Upload;
  license_back: Upload;
  document_number?: string;
  document_state?: Option;
  issue_state?: Option;
}

async function getVehicles() {
  const {data} = await api.get<ListResponse<Vehicle>>('/vehicles/');

  return data;
}

async function getEmployeeById({queryKey}: QueryFunctionContext) {
  const [, employeeId] = queryKey;

  if (!employeeId) return undefined;

  const {data} = await api.get<User>(`/users/${employeeId}`);

  return data;
}

function useEmployeeForm() {
  const {employeeId} = useParams<{employeeId: string}>();
  const {push} = useHistory();
  const [submitting, setSubmitting] = useState(false);

  const {
    handleSubmit,
    register,
    control,
    formState: {errors},
    reset,
    watch,
    setValue,
  } = useForm<FormValue>();

  const role = watch('role');
  const license_front = watch('license_front');
  const license_back = watch('license_back');

  const license_files = useMemo(
    function () {
      return [license_front, license_back].filter((item) => Boolean(item));
    },
    [license_front, license_back]
  );

  const {data: vehicles} = useQuery('vehicles', getVehicles);
  const {data: employee} = useQuery(['employee', employeeId], getEmployeeById);

  
  
  const options = useMemo(
    function () {
      if (!vehicles) return [];
      
      return vehicles.results
      .filter((vehicle) => !vehicle.is_assigned)
      .map((v) => ({value: v.id, label: v.nickname}));
    },
    [vehicles]
    );

    

  useEffect(
    function () {
      if (employee) {
        const {driver, ...user} = employee;
        if (!user) {
          return;
        }
        reset({
          ...user,
          state: states.find((s) => s.value === user.state),
          role: roles.find((r) => r.value === employee.role),
          vehicle:employee.driver?.vehicle?{value:employee.driver?.vehicle.id,label:employee.driver?.vehicle.nickname}:{value:0,label:''},
          document_number: driver?.document_number,
          document_state: {value: driver?.document_state},
          issue_state: states.find((s) => s.value === driver?.issue_state),
          license_front: driver?.license_front,
          license_back: driver?.license_back,
        });
      }
    },
    [employee, reset]
  );

  const handleFileUpload = useCallback(
    function (uploads: Upload[]) {
      setValue('license_front', uploads[0]);
      setValue('license_back', uploads[1]);
    },
    [setValue]
  );

  async function submit(data: FormValue) {
    setSubmitting(true);

    const user = {
      ...data,
      state: data.state?.value,
      role: data.role.value,
    };

    const {data: userData} = await (employeeId
      ? api.patch<User>(`/users/${employeeId}/`, user)
      : api.post<User>('/users/', user));

      

    if (user.role === 'driver') {
      const driver = {
        id: employee?.driver?.id,
        document_number: data.document_number,
        document_state: data.document_state?.value,
        issue_state: data.issue_state?.value,
        vehicle: data.vehicle?.value,
        license_front: data.license_front?.id,
        license_back: data.license_back?.id,
        user: userData.id,
      };

      if (driver.id) {
        await api.patch(`/drivers/${driver.id}/`, driver);
      } else {
        await api.post('/drivers/', driver);
      }
    }

    setSubmitting(false);
    push('/settings/employees');
  }

  return {
    employeeId,
    options,
    submitting,
    errors,
    control,
    role: role?.label,
    license_files,
    register,
    handleFileUpload,
    handleSubmit: handleSubmit(submit),
  };
}

export default useEmployeeForm;
