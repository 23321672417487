import React, {useState} from 'react';
import {Address} from 'types/order';
import {createCard} from 'types/order';
import styles from './CardInformation.module.scss';
import Typography from 'components/Typography';
import Button from 'components/Button';
import Modal from 'components/Modal';
import {Controller, useForm} from 'react-hook-form';
import FormGroup from 'components/FormGroup';
import useUserForm from '../useUserForm';
import toast from 'react-hot-toast';
import TextField from 'components/TextField/TextField';
import SelectField from 'components/SelectField';
import {get} from 'lodash';
import closeIcon from '../../RouteHistory/components/Ticket/close.svg';

export enum CardType {
  VISA = 'visa',
  MASTERCARD = 'mastercard',
  AMEX = 'amex',
  DINERS = 'diners',
  DISCOVER = 'discover',
  JCB = 'jcb',
}

function getCardType(number: string): CardType | null {
  const numberStripped = number.replace(/\s+/g, '');
  const cards: Record<CardType, RegExp> = {
    visa: /^4[0-9]{12}(?:[0-9]{3})?/,
    mastercard: /^5[1-5][0-9]{14}/,
    amex: /^3[47][0-9]{13}/,
    diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
    discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
    jcb: /^(?:2131|1800|35\d{3})\d{11}/,
  };
  for (const card in cards) {
    if (cards[card as CardType].test(numberStripped)) {
      return card as CardType;
    }
  }
  return null;
}

function cc_format(value: string) {
  var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
  var matches = v.match(/\d{4,16}/g);
  var match = (matches && matches[0]) || '';
  var parts = [];

  for (let i = 0, len = match.length; i < len; i += 4) {
    parts.push(match.substring(i, i + 4));
  }

  if (parts.length) {
    return parts.join(' ');
  } else {
    return value;
  }
}

function dd_format(value: string) {
  var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
  var matches = v.match(/\d{2,4}/g);
  var match = (matches && matches[0]) || '';
  var parts = [];

  for (let i = 0, len = match.length; i < len; i += 2) {
    parts.push(match.substring(i, i + 2));
  }

  if (parts.length) {
    return parts.join('/');
  } else {
    return value;
  }
}

type AddressEntity = Address & {id: number};
type FormData = Omit<createCard, 'label'>;

type UserAddressesProps = {
  userId?: string;
  addresses: AddressEntity[];
  onChange?: (valud: AddressEntity[]) => void;
};

const CardInformation: React.FC<UserAddressesProps> = ({addresses, userId}) => {
  const {createNewCard, deleteCard, cards} = useUserForm();

  const [openModal, setOpenModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeCard, setActiveCard] = useState(null);

  const handleOrdersClose = () => setOpenModal(false);

  const {
    control,
    setValue,
    handleSubmit,
    // formState: {},
    reset,
  } = useForm<FormData>();

  const handleCreation = async (data: any) => {
    setLoading(true);
    const body = {
      customer: Number(userId),
      card_number: data.card_number,
      exp_year: data.expiry_date.split('/')[1],
      exp_month: data.expiry_date.split('/')[0],
      cardholder_name: data.holder_name,
      card_type: getCardType(data.card_number),
      cvc: data.secure_code,
      billing_address: data.billing_address.value,
    };

    try {
      await createNewCard(body);
      setLoading(false);
      setOpenModal(false);
      reset();
    } catch (error) {
      toast.error('Something wrong with creation. Try again later !');
      setLoading(false);
    }
  };

  const onDelete = (item: any) => {
    setActiveCard(item);
    setDeleteModal(true);
  };

  const deleteCardHandler = async () => {
    setLoading(true);
    try {
      await deleteCard(get(activeCard, 'id'));
      setLoading(false);
      setDeleteModal(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className={styles.accordion}>
      <div>
        <Typography variant="h3" className={styles.accordion__title}>
          Card Information
        </Typography>
        <div className={styles.accordion__inner}>
          {cards.map((card) => (
            <div className={styles.accordion__item} key={get(card, 'id')}>
              <div className={styles.card}>
                <div className={styles.accordion__item_header}>
                  <div>{get(card, 'cardholder_name', '')}</div>
                  <img
                    className={styles.close_icon}
                    alt="close"
                    src={closeIcon}
                    onClick={() => onDelete(card)}
                  />
                </div>
                <div className={styles.row}>
                  <div>**** **** **** {get(card, 'last4', '')}</div>
                  <div>
                    Expiration: {get(card, 'exp_month', '')}/
                    {get(card, 'exp_year', '')}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Button
        style={{
          marginRight: 20,
          minHeight: 40,
          marginLeft: 'auto',
          marginTop: 10,
        }}
        color="green"
        onClick={() => setOpenModal(true)}
      >
        Add New Card
      </Button>

      <Modal
        isOpen={openModal}
        onClose={handleOrdersClose}
        className={styles.list_wrapper}
      >
        <div className={styles.modalWrapper}>
          <FormGroup>
            <Controller
              name="card_number"
              control={control}
              rules={{
                required: 'Please, fill card number',
                minLength: {
                  value: 19,
                  message: 'Please, fill card number',
                },
              }}
              render={({field, fieldState}) => (
                <TextField
                  label="Card Number"
                  onChange={(event) => {
                    setValue('card_number', cc_format(event.target.value));
                  }}
                  wrapperClass={styles.googleInput}
                  placeholder="**** **** **** 1234"
                  hasError={false}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )}
            />
          </FormGroup>
          <FormGroup columns={2} className={styles.modalWrapper}>
            <Controller
              name="expiry_date"
              control={control}
              rules={{
                required: 'Please, fill expiry date',
                minLength: {
                  value: 5,
                  message: 'Please, fill expiry date',
                },
              }}
              render={({field, fieldState}) => (
                <TextField
                  label="Expiry date"
                  wrapperClass={styles.googleInput}
                  placeholder="mm/yy"
                  hasError={false}
                  onChange={(event) => {
                    setValue('expiry_date', dd_format(event.target.value));
                  }}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )}
            />
            <Controller
              name="secure_code"
              control={control}
              rules={{
                required: 'Please, fill secure code',
                minLength: {
                  value: 3,
                  message: 'Please, fill secure code',
                },
              }}
              render={({field, fieldState}) => (
                <TextField
                  maxLength={3}
                  label="Secure code"
                  value={field.value}
                  wrapperClass={styles.googleInput}
                  placeholder="CVC"
                  hasError={false}
                  onChange={(event) => {
                    setValue(
                      'secure_code',
                      event.target.value.replace(/\D/g, '')
                    );
                  }}
                  error={fieldState.error?.message}
                />
              )}
            />
          </FormGroup>
          <FormGroup className={styles.switchForm}>
            <Controller
              name="holder_name"
              control={control}
              rules={{required: 'Please, fill holder name'}}
              render={({field, fieldState}) => (
                <TextField
                  label="Card holder name"
                  wrapperClass={styles.googleInput}
                  placeholder="Enter card holder name"
                  hasError={false}
                  onChange={field.onChange}
                  error={fieldState.error?.message}
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Controller
              name="billing_address"
              control={control}
              rules={{required: 'Please select address'}}
              render={({field, fieldState}) => (
                <SelectField
                  {...field}
                  label="Billing Address"
                  placeholder="Select address"
                  options={addresses
                    .filter((item) => item.type === 'billing')
                    .map((item) => ({
                      value: item.id,
                      label: `${item.address_line_1}, ${item.city}, ${item.state}, ${item.zipcode}`,
                    }))}
                  error={fieldState.error?.message}
                  value={field.value}
                  onChange={(newValue) => {
                    if (newValue) {
                      setValue('billing_address', newValue);
                    }
                  }}
                />
              )}
            />
          </FormGroup>
          <div className={styles.actionWrapper}>
            <Button
              className={styles.cancelButton}
              onClick={() => {
                setOpenModal(false);
              }}
              component="a"
              color="gray"
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              type="submit"
              onClick={handleSubmit(handleCreation)}
              color="green"
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        onRequestClose={() => setDeleteModal(false)}
        className={styles.delete_wrapper}
      >
        <p className={styles.modalHeader}>
          Are you sure, you want to delete the card ?
        </p>
        <div className={styles.accordion}>
          <div className={styles.accordion__inner}>
            <div className={styles.accordion__item}>
              <div className={styles.card}>
                <div className={styles.accordion__item_header}>
                  <div>{get(activeCard, 'cardholder_name', '')}</div>
                </div>
                <div className={styles.row}>
                  <div>**** **** **** {get(activeCard, 'last4', '')}</div>
                  <div>
                    Expiration: {get(activeCard, 'exp_month', '')}/
                    {get(activeCard, 'exp_year', '')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Button
          className={styles.delete}
          onClick={deleteCardHandler}
          color="danger"
          size="md"
          loading={loading}
        >
          Delete card
        </Button>
      </Modal>
    </div>
  );
};

export default CardInformation;
