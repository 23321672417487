import {useQuery} from 'react-query';
import {DriverService} from 'services/driver.service';

export type DriverFilter = {
  limit?: number;
};

const useDrivers = (filter: DriverFilter = {}) => {
  const {data, isLoading} = useQuery({
    queryFn: async () => {
      const driverService = new DriverService();
      const response = await driverService.getDriversPaginated(filter);
      return response.data;
    },
    queryKey: ['drivers', filter.limit],
  });

  return {
    drivers: data?.results || [],
    isLoading,
  };
};

export default useDrivers;
