import React, {useRef} from 'react';
import {get} from 'lodash';

import styles from './styles.module.scss';
import {format} from 'date-fns';
import CardContent from 'components/CardContent';
import ReactToPrint from 'react-to-print';
import PrintIcon from 'icons/PrintIcon';

type Props = {
  order: any;
};

const returnType = (type: string) => {
  if (type === 'patient_app') {
    return 'Customer App';
  }
  if (type === 'pos') {
    return 'POS';
  }
  if (type === 'dashboard') {
    return 'Dashboard';
  }
  if (type === 'driver_app_cod') {
    return 'Driver App COD';
  }
};

function SignedDocument({order}: Props) {
  const checkRef = useRef(null);

  return (
    <>
      <div ref={checkRef} className={styles.root}>
        <CardContent dark>
          <div className={styles.center}>
            <p>Medications & Essentials Delivered Swiftly Inc.</p>
            <p>1104 2nd Ave New York, NY 10022</p>
            <p>
              Phone: <span>(212) 371-6000 | Fax: (212) 371-2255</span>
            </p>
          </div>
        </CardContent>
        <CardContent>
          <p
            style={{
              marginBottom: 5,
              marginTop: 0,
              paddingLeft: 50,
              paddingRight: 50,
            }}
          >
            <b>Customer name:</b> {get(order, 'customer.user.first_name', '')}{' '}
            {get(order, 'customer.user.last_name', '')}
          </p>
          <div
            style={{
              justifyContent: 'flex-start',
              marginTop: 0,
              paddingLeft: 50,
              paddingRight: 50,
            }}
            className={styles.row}
          >
            <div>
              <p>
                <b>Date:</b>{' '}
                {get(order, 'transaction_date')
                  ? format(
                      new Date(get(order, 'transaction_date')),
                      'MM/dd/yyyy p'
                    )
                  : ''}
              </p>
              <p>
                <b>Trans Type:</b> Sale
              </p>
            </div>
            <div style={{flex: 1, marginLeft: 95}}>
              <p>
                <b>Trans#:</b> {get(order, 'transaction_id', 0)}
              </p>
              <p>
                <b>Source:</b> {returnType(get(order, 'transaction_origin'))}
              </p>
            </div>
          </div>

          {/* <div
            style={{paddingLeft: 50, paddingRight: 50}}
            className={styles.row}
          >
            <p style={{fontSize: 15}}>Qty Description</p>
            <p style={{fontSize: 15}}>Price (${get(order, 'amount', '')})</p>
          </div> */}
          <div
            style={{paddingLeft: 50, paddingRight: 50}}
            className={styles.row}
          >
            <p>{get(order, 'orders[0].items[0].quantity', '')}</p>
            <p>{get(order, 'orders[0].items[0].paid', '')}</p>
          </div>
          <hr style={{borderWidth: 0.3}} />
          <div style={{paddingLeft: 50, paddingRight: 50}}>
            <table className={styles.tableRx}>
              <tr>
                <td>
                  <p>
                    <b>Order #</b>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Amount</b>
                  </p>
                </td>
              </tr>

              {get(order, 'list_rx', []).map((item: any) => (
                <tr key={item.id}>
                  <td>
                    <p>{item.id || '-'}</p>
                  </td>
                  <td>
                    <p>${Number(item.amount_due).toFixed(2)}</p>
                  </td>
                </tr>
              ))}
            </table>
            <div className={styles.row}>
              <p>
                <b>Sub Total:</b>
              </p>
              <p>${get(order, 'amount', '')}</p>
            </div>
            <div className={styles.row}>
              <p>
                <b>Discount:</b>
              </p>
              <p>N/A</p>
            </div>
            <div className={styles.row}>
              <p>
                <b>Tax:</b>
              </p>
              <p>N/A</p>
            </div>
            <div className={styles.row}>
              <p>
                <b>Total Amount Due:</b>
              </p>
              <p>${get(order, 'amount', '')}</p>
            </div>
          </div>
          <hr style={{borderWidth: 0.3}} />
          <div style={{paddingLeft: 50, paddingRight: 50}}>
            <div className={styles.row}>
              <p>Amount Tendered</p>
              <p>${get(order, 'amount', '')}</p>
            </div>
            <div className={styles.row}>
              <p>{get(order, 'cardType', '') || 'Card Number'}</p>
              <p>
                {get(order, 'masked_card_number', '****-****') || '****-****'}
              </p>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          ></div>
        </CardContent>

        <div className={styles.print}>
          <ReactToPrint
            trigger={() => (
              <button className={styles.panel__deliveries_print_action}>
                <PrintIcon size={16} />
              </button>
            )}
            content={() => checkRef.current}
          />
        </div>
      </div>
    </>
  );
}

export default SignedDocument;
