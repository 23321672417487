import classNames from 'classnames';
import Button from 'components/Button';
import Ticket from './components/Ticket';
// import Map from './components/Map';
import SignedDocument from './components/SignedDocument';
import useDeliveryPlanner from './useDeliveryPlanner';
import stat_time from './icons/stat_time.svg';

import styles from './DeliveryPlanner.module.scss';
import React, {useEffect, useRef, useState} from 'react';
import {get} from 'lodash';
import {add, parseISO} from 'date-fns';
import {utcToZonedTime, format} from 'date-fns-tz';
import FormGroup from 'components/FormGroup';
import PrintIcon from 'icons/PrintIcon';
import ReactToPrint from 'react-to-print';
import Modal from 'components/Modal';
import {Order} from 'types';
import {Link, useLocation} from 'react-router-dom';
import {useForm, useFormState} from 'react-hook-form';
import AssignedHeader from './components/AssignedHeader';
import NewMap from 'pages/DeliveryPlanner/components/NewMap';

type noteTypes = {
  [key: string]: object;
};

type LocationProps = {
  showPdf: boolean;
  order_id: string;
};

function DeliveryPlanner() {
  const {
    route,
    selectedAssignedOrders,
    onChangeAssignedCheckbox,
    sendDriverNote,
    roundedTime,
    currentVehicle,
    vehicles,
    handleDeleteOrder,
    handleDeleteAll,
  } = useDeliveryPlanner();
  const {handleSubmit, control, register, setValue, reset} = useForm();
  const {isSubmitting} = useFormState({control});

  const [orderList, setOrderList] = useState<Order[] | null>(null);
  const [activeOrder, setActiveOrder] = useState({});
  const [noteModal, setNoteModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [isDeleteAll, setIsDeleteAll] = useState(false);
  const [notes, setNotes] = useState<noteTypes>({});

  const location = useLocation<LocationProps | null>();

  const documentRef = useRef<HTMLDivElement>(null);
  const foundOrder = useRef(false);

  useEffect(() => {
    if (route && location.state?.showPdf) {
      route.deliveries.forEach((item) => {
        const foundItem = item.orders.find(
          (item) => item.new_leaf_order === String(location.state?.order_id)
        );
        if (foundItem) {
          setOrderList(item.orders);
          setActiveOrder(item);
          setValue('driverNote', get(item, 'note', ''));
          foundOrder.current = true;
        }
      });
    }
  }, [location, notes, route, setValue]);

  const handleOrdersClick =
    (order: Order) => (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      setActiveOrder(order);
      setOrderList(order.orders);
    };
  const handleNoteClick = (id: number, staff: string) => {
    setNoteModal(true);
    setValue('driverNote', staff ? staff : get(notes[`${id}`], 'note', ''));
  };

  const handleOrdersClose = () => {
    setOrderList(null);
    setNoteModal(false);
    foundOrder.current = false;
  };

  const deleteOrder = async () => {
    setDeleting(true);
    if (isDeleteAll) {
      await handleDeleteAll();
    } else {
      await handleDeleteOrder(get(activeOrder, 'id', 0));
    }
    setDeleting(false);
    setDeleteModal(false);
  };

  const noteSubmit = async (value: any) => {
    const body = {
      id: Number(get(activeOrder, 'id', '')),
      note: String(value?.driverNote),
    };
    const res = await sendDriverNote(body);
    if (res.status === 201) {
      setNotes((pr) => ({...pr, [`${get(activeOrder, 'id', '')}`]: body}));
      reset();
      setNoteModal(false);
      // refetch();
    }
  };

  const orderListRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div
        ref={orderListRef}
        style={{position: 'absolute', zIndex: -100, display: 'none'}}
        className={classNames(styles.panel__content, styles['--assigned'])}
      >
        {route.deliveries.map((ticket, index) => {
          return (
            <Ticket
              isAssigned={true}
              key={ticket.id}
              isPrint={true}
              showTime={route.status === 'processed'}
              index={index + 1}
              selected={selectedAssignedOrders.includes(ticket.id)}
              onChangeCheckbox={onChangeAssignedCheckbox}
              onOrdersClick={handleOrdersClick(ticket)}
              {...ticket}
              handleNoteClick={() => {
                setActiveOrder(ticket);
                handleNoteClick(
                  ticket.id,
                  String(get(ticket, 'staff_note', '') || '')
                );
              }}
            />
          );
        })}
      </div>

      <div className={styles.root}>
        <div className={styles.panel}>
          <AssignedHeader
            driver={
              get(route, 'driver.first_name', '') +
              ' ' +
              get(route, 'driver.last_name', '')
            }
            roundedTime={roundedTime}
            currentVehicle={currentVehicle}
            vehicles={vehicles?.results}
            startLocation={
              route.start_location
                ? {
                    name: route.start_location,
                    lat: route.end_latitude,
                    lng: route.start_longitude,
                  }
                : {
                    name: '1100 2nd Ave, New York, NY 10022',
                    lat: 0,
                    lng: 0,
                  }
            }
            routes={route.deliveries.length}
            start_time={route.start_time}
            endLocation={
              route.end_location
                ? {
                    name: route.end_location,
                    lat: route.end_latitude,
                    lng: route.end_longitude,
                  }
                : {
                    name: '1100 2nd Ave, New York, NY 10022',
                    lat: 0,
                    lng: 0,
                  }
            }

            // onChange={onChangeRouteParams}
            // onChangeStartTime={onChangeStartTime}
          />

          <div
            className={classNames(styles.panel__content, styles['--assigned'])}
          >
            {route.deliveries.map((ticket, index) => {
              // console.log('Ticket:', ticket);
              return (
                <Ticket
                  isAssigned={route.status !== 'completed'}
                  key={ticket.id}
                  showTime={true}
                  index={index + 1}
                  selected={selectedAssignedOrders.includes(ticket.id)}
                  onChangeCheckbox={onChangeAssignedCheckbox}
                  onOrdersClick={handleOrdersClick(ticket)}
                  {...ticket}
                  isCanceled={true}
                  onDelete={() => {
                    setIsDeleteAll(false);
                    setDeleteModal(true);
                    setActiveOrder(ticket);
                  }}
                  handleNoteClick={() => {
                    setActiveOrder(ticket);
                    handleNoteClick(
                      ticket.id,
                      String(get(ticket, 'staff_note', '') || '')
                    );
                  }}
                />
              );
            })}
            {route.canceled_deliveries.map((ticket, index) => {
              // console.log('Ticket:', ticket);
              return (
                <Ticket
                  isAssigned={route.status !== 'completed'}
                  key={ticket.id}
                  showTime={true}
                  index={index + 1}
                  selected={selectedAssignedOrders.includes(ticket.id)}
                  onChangeCheckbox={onChangeAssignedCheckbox}
                  onOrdersClick={handleOrdersClick(ticket)}
                  {...ticket}
                  onDelete={() => {
                    setIsDeleteAll(false);
                    setDeleteModal(true);
                    setActiveOrder(ticket);
                  }}
                  handleNoteClick={() => {
                    setActiveOrder(ticket);
                    handleNoteClick(
                      ticket.id,
                      String(get(ticket, 'staff_note', '') || '')
                    );
                  }}
                />
              );
            })}
          </div>

          <div className={styles.panel__assigned_buttom}>
            {route.status === 'awaiting' && (
              <Button
                onClick={() => {
                  setIsDeleteAll(true);
                  setDeleteModal(true);
                }}
                className={styles.cancel}
                color="danger"
                size="md"
                loading={deleting}
              >
                Cancel all remaining orders
              </Button>
            )}
            <div className={styles.panel__assigned_summary}>
              {/* @ts-ignore */}
              <ReactToPrint
                trigger={() => (
                  <button className={styles.panel__deliveries_print_action}>
                    <PrintIcon size={16} />
                  </button>
                )}
                onBeforeGetContent={() => {
                  orderListRef.current?.setAttribute(
                    'style',
                    'display:block; z-index:-1000;position:absolute'
                  );
                }}
                onAfterPrint={() => {
                  orderListRef.current?.setAttribute(
                    'style',
                    'display:none; z-index:-1000;position:absolute'
                  );
                }}
                onPrintError={() => {
                  orderListRef.current?.setAttribute(
                    'style',
                    'display:none; z-index:-1000;position:absolute'
                  );
                }}
                content={() => orderListRef.current}
              />
              <div className={styles.panel__deliveries_end_time}>
                <img alt="timer" src={stat_time} />
                <span>Ends at</span>{' '}
                {route?.start_time && route.total_time
                  ? format(
                      utcToZonedTime(
                        add(parseISO(route.start_time), {
                          minutes: route.total_time,
                        }),
                        'America/New_York'
                      ),
                      'h:mm aaa',
                      {
                        timeZone: 'America/New_York',
                      }
                    )
                  : '-:-- --'}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={classNames(styles.panel__header)}>
            <span className={styles.panel__inbox_title}>Preview</span>
          </div>

          <NewMap
            end_location={{
              name: route.end_location,
              lat: route.end_latitude,
              lng: route.end_longitude,
            }}
            start_location={{
              name: route.start_location,
              lat: route.start_latitude,
              lng: route.start_longitude,
            }}
            orders={route.deliveries}
            display={true}
          />
          {/* 
          <Map
            orders={route.deliveries}
            start_location={route.start_location}
            end_location={route.end_location}
            status={route.status}
            display={true}
          /> */}
        </div>
        <Modal
          isOpen={!!orderList || noteModal}
          onClose={handleOrdersClose}
          onRequestClose={handleOrdersClose}
          onAfterOpen={() => {
            if (foundOrder.current) {
              documentRef.current?.scrollIntoView();
            }
          }}
          className={styles.list_wrapper}
        >
          {orderList && (
            <>
              <div className={styles.list}>
                <div
                  className={classNames(
                    styles.list__item_header,
                    styles.list__item
                  )}
                >
                  <p>Order #</p>
                  <p>Preference date</p>
                  {/* <p>Count of items</p> */}
                </div>
                {orderList?.map(
                  ({
                    new_leaf_order,
                    preference_date,
                    items,
                    preference_time,
                  }) => (
                    <Link
                      key={new_leaf_order}
                      to={`/requests/${new_leaf_order}`}
                    >
                      <div className={styles.list__item}>
                        <p className={styles.list__id}>#M{new_leaf_order}</p>
                        <p className={styles.list__id}>
                          {preference_date || '--/--/----'}
                          {preference_time ? `/${preference_time}` : ''}
                        </p>
                        {/* <p className={styles.list__count}>
                        {items.length}
                      </p> */}
                      </div>
                    </Link>
                  )
                )}
              </div>
              <div className={styles.list_total}>
                Total items:
                {orderList?.reduce(
                  //@ts-ignore
                  (prev, item) => prev + item.items.length,
                  0
                )}
              </div>
            </>
          )}
          {noteModal && (
            <form
              className={styles.noteFormWrapper}
              onSubmit={handleSubmit(noteSubmit)}
            >
              <FormGroup>
                <div>Dispatch note for Field Members:</div>
                <textarea
                  className={styles.noteInput}
                  rows={5}
                  {...register('driverNote')}
                />
              </FormGroup>
              <div className={styles.actions}>
                <Button
                  color="outlined"
                  size="md"
                  onClick={() => handleOrdersClose()}
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  color="green"
                  size="md"
                  loading={isSubmitting}
                >
                  Save
                </Button>
              </div>
            </form>
          )}

          <div>Field member note for Dispatch:</div>
          {get(activeOrder, 'note', '') !== null ? (
            <textarea
              disabled={true}
              value={get(activeOrder, 'note', '')}
              className={styles.orderNote}
            />
          ) : (
            <textarea disabled={true} value={''} className={styles.orderNote} />
          )}

          {!!get(activeOrder, 'delivery_image', null) && (
            <div className={styles.delivery_image}>
              <p>Delivery image:</p>
              <img
                alt="delivery_image"
                src={get(activeOrder, 'delivery_image.medium', '')}
              />
            </div>
          )}
          <SignedDocument
            ref={documentRef}
            driver={route.driver}
            order={activeOrder}
          />
        </Modal>

        <Modal
          isOpen={deleteModal}
          onClose={() => setDeleteModal(false)}
          onRequestClose={() => setDeleteModal(false)}
          className={styles.delete_wrapper}
        >
          <p className={styles.modalHeader}>
            {isDeleteAll
              ? 'Are you sure, you want to cancel ?'
              : 'Are you sure to cancel order ?'}
          </p>
          {isDeleteAll ? (
            route.deliveries
              .filter((item) => item.status !== 'completed')
              .map((item) => (
                <div
                  className={classNames(
                    styles.list__item_header,
                    styles.list__item
                  )}
                >
                  <p>Group #</p>
                  <p>{get(item, 'id', 0)}</p>
                </div>
              ))
          ) : (
            <>
              <div
                className={classNames(
                  styles.list__item_header,
                  styles.list__item
                )}
              >
                <p>Group #</p>
                <p>{get(activeOrder, 'id', 0)}</p>
              </div>
              <div
                className={classNames(
                  styles.list__item_header,
                  styles.list__item
                )}
              >
                <p>Delivery address #</p>
                {typeof get(activeOrder, 'delivery_address') === 'string' ? (
                  <p>{get(activeOrder, 'delivery_address')}</p>
                ) : (
                  <p>
                    {get(activeOrder, 'delivery_address.address_line_1') || ''}{' '}
                    {get(activeOrder, 'delivery_address.address_line_2') || ''}
                    {get(activeOrder, 'delivery_address.city') || ''},{' '}
                    {get(activeOrder, 'delivery_address.state')}{' '}
                    {get(activeOrder, 'delivery_address.zipcode')}
                  </p>
                )}
              </div>
            </>
          )}
          <Button
            className={styles.delete}
            onClick={deleteOrder}
            color="danger"
            size="md"
            loading={deleting}
          >
            Cancel {isDeleteAll ? 'all' : ''}
          </Button>
        </Modal>
      </div>
    </>
  );
}

export default DeliveryPlanner;
