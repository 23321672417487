import Button from 'components/Button';
import DataTable from 'components/DataTable';
import ListActionsWrapper from 'components/ListActionsWrapper';
import ListWrapper from 'components/ListWrapper';
import SearchInput from 'components/SearchInput';
import PageHeader from 'components/PageHeader';
import Typography from 'components/Typography';

import useVehicles from './useVehicles';

import styles from '../Settings.module.scss';
import {DataTableSchemaItem} from 'types';

const SCHEMA: DataTableSchemaItem[] = [
  {
    dataKey: 'nickname',
    header: 'Vehicle nickname',
    sortable: true,
  },
  {
    dataKey: 'license_plate_number',
    header: 'Vehicle plate',
    sortable: true,
  },
  {
    dataKey: 'driver',
    header: 'Assigned driver',
    sortable: true,
  },
  {
    dataKey: 'vehicle_type',
    header: 'Vehicle type',
    align: 'center',
    sortable: false,
  },
  {
    dataKey: 'view',
    header: '',
    align: 'right',
  },
];

function Vehicles() {
  const {data, isLoading, sortBy, sortOrder, onSort, keyWord, setKeyWord} =
    useVehicles();

  return (
    <div className={styles.main_container}>
      <PageHeader>
        <Typography variant="h2">Vehicles</Typography>
        <Button to="/settings/vehicles/new" component="a" color="green">
          New Vehicle
        </Button>
      </PageHeader>

      <ListWrapper>
        <ListActionsWrapper className={styles.list_header}>
          <SearchInput
            value={keyWord}
            onChange={(val) => {
              setKeyWord(val.target.value);
              sessionStorage.setItem(`search_vehicle`, val.target.value);
            }}
            size="sm"
          />
        </ListActionsWrapper>
        <DataTable
          sortBy={sortBy}
          sortOrder={sortOrder}
          onSort={onSort}
          data={data}
          schema={SCHEMA}
          loading={isLoading}
          selectable={false}
        />
      </ListWrapper>
    </div>
  );
}

export default Vehicles;
