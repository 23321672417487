import {useMemo, useState} from 'react';
import {QueryFunctionContext, useQuery} from 'react-query';

import api from 'api';
import {DataItem, ListResponse, Vehicle} from 'types';
import {getFullName} from 'helpers/user';
import ViewDetails from 'components/ViewDetails';
import useSort from 'hooks/useSort';

async function getVehicles({queryKey}: QueryFunctionContext) {
  const [, order, keyWord] = queryKey;
  const {data} = await api.get<ListResponse<Vehicle>>('/vehicles/', {
    params: {order, search: keyWord ? keyWord : undefined},
  });

  return data;
}

function mapVehicles(vehiles: Vehicle[]): DataItem[] {
  return vehiles.map((v) => ({
    ...v,
    driver: getFullName(v.driver) || '-',
    view: <ViewDetails to={`/settings/vehicles/${v.id}`} />,
  }));
}

function useVehicles() {
  const [keyWord, setKeyWord] = useState(
    sessionStorage.getItem(`search_vehicle`) || ''
  );
  const {sortBy, sortOrder, order, onSort} = useSort('nickname');
  const {data: vehicles, isLoading} = useQuery(
    ['vehicles', order, keyWord],
    getVehicles
  );

  const data = useMemo(
    function () {
      return vehicles ? mapVehicles(vehicles.results) : [];
    },
    [vehicles]
  );

  return {
    data,
    vehicles: vehicles?.results || [],
    isLoading,
    sortBy,
    sortOrder,
    onSort,
    keyWord,
    setKeyWord,
  };
}

export default useVehicles;
