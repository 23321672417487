import {AxiosResponse} from 'axios';
import {DriverFilter} from 'hooks/useDrivers';
import {Driver, PaginatedResponse} from 'types';
import {ApiClient} from './api-client';

export class DriverService extends ApiClient {
  async getDriversPaginated({
    limit = 20,
  }: DriverFilter): Promise<AxiosResponse<PaginatedResponse<Driver>>> {
    return this.get(`/drivers/`, {limit});
  }
}
