import Spinner from 'components/Spinner';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import Note from 'components/Note';
import NoteForm from 'components/NoteForm';
import ImageView from 'components/ImageView';

import CustomerDetails from './components/CustomerDetails';
import Header from './components/Header';
import Prescriptions from './components/Prescriptions';
import PreferenceTime from './components/PreferenceTime';
import Otcs from './components/Otcs';
import useRequestDetails from './useRequestDetails';
import useUpdateDeliveryAddress from './useUpdateDeliveryAddress';

import styles from './RequestDetails.module.scss';
import useSendCustomerNotification from 'hooks/useSendCustomerNotification';
import React, {Key, useCallback, useEffect, useRef, useState} from 'react';
import toast from 'react-hot-toast';
import useRequestSignature from '../../hooks/useRequestSignature';
import Modal from '../../components/Modal';
import Typography from '../../components/Typography';
import {get} from 'lodash';
import Button from '../../components/Button';
import FormGroup from 'components/FormGroup';
import {Controller, useForm} from 'react-hook-form';
import {
  GooglePlaceAutoComplete,
  TypeGooglePlaceDetail,
} from 'components/GooglePlaceAutoComplete';
import TextField from 'components/TextField';
import useUserForm from 'pages/UserForm/useUserForm';
import {createAddress, SelectOption} from 'types';

type FormData = createAddress;

const priority: SelectOption[] = [
  {
    label: 'High',
    value: 'high',
  },
  {
    label: 'Medium',
    value: 'medium',
  },
  {
    label: 'Low',
    value: 'low',
  },
];

// @ts-ignore
function RequestDetails() {
  const {
    id,
    showInsurance,
    data,
    markDisabled,
    setShowInsurance,
    onChangeScriptStatus,
    onSubmitNote,
    complete,
    chargeCard,
    onChangeTime,
    onChangePriority,
  } = useRequestDetails();

  const {addressIsUpdating, updateDeliveryAddress} = useUpdateDeliveryAddress();

  const {sendNotification} = useSendCustomerNotification();
  const {requestSignature} = useRequestSignature();
  const [modalOpen, setModalOpen] = useState(false);
  const [openAddressModal, setOpenAddressModal] = useState(false);
  const [selectCardModal, setSelectCardModal] = useState(false);
  const [accessCardModal, setAccessCardModal] = useState(false);
  const [deliveryAddressId, setDeliveryAddressId] = useState(
    data?.delivery_address?.id
  );
  const [cardId, setCardIs] = useState(0);

  const {control, setValue, handleSubmit, reset} = useForm<FormData>();
  const {createNewAddress, cards} = useUserForm(data?.customer.id);

  useEffect(() => {
    if (cards.length > 0) {
      setCardIs(cards[0].custom_uuid);
    }
  }, [cards]);

  const itemId = useRef<number | undefined>(undefined);

  const handleCreation = async (datas: createAddress) => {
    const res = await createNewAddress({
      ...datas,
      customer_id: data?.customer.id,
    });

    if (get(res, 'status') === 200) {
      setOpenAddressModal(false);
      setDeliveryAddressId(res.data.id);
      addressChangeHandler(res.data.id);
      reset();
    } else {
      toast.error('Something wrong with creation. Try again later !');
    }
  };

  const handleNotifyCustomer = useCallback(
    async (id: number | undefined) => {
      itemId.current = id;
      if (!data?.id) {
        return;
      }

      if (data.prescriptions.length > 0) {
        await sendNotification({
          requestId: data?.id,
          request_drugs:
            data.status === 'completed'
              ? data.prescriptions.filter((item) =>
                  itemId.current
                    ? itemId.current === item.id
                    : item.status !== 'completed'
                )
              : itemId.current
              ? data.prescriptions.filter((item) => item.id === itemId.current)
              : data.prescriptions,
        });
        toast.success('Notification sent successfully');
      }
    },
    [data?.id, data?.status, sendNotification, data?.prescriptions]
  );

  const onCharge = async (id: number | undefined) => {
    itemId.current = id;
    if (cards.length < 1) {
      toast.error('Please add billing card to user first');
      return;
    }
    if (cards.length > 1) {
      setSelectCardModal(true);
      return;
    }
    setAccessCardModal(true);
  };

  const submitSelectedCard = async () => {
    if (!cardId) {
      return;
    }
    try {
      await chargeCard({
        customer: data?.customer.id,
        payment_method_uuid: cardId,
        copay_items: itemId.current
          ? get(data, 'prescriptions', []).filter(
              (item) => item.id === itemId.current
            )
          : get(data, 'prescriptions', []).filter((item) => !item.is_paid),
      });
      setAccessCardModal(false);
      setSelectCardModal(false);
      toast.success('Successfully');
    } catch (error) {
      toast.success('Error try again');
    }
  };

  const handleRequestSignature = useCallback(async () => {
    if (!data?.id) {
      return;
    }
    await requestSignature({
      requestId: data?.id,
    });
    toast.success('Notification sent successfully');
  }, [data?.id, requestSignature]);

  const addressChangeHandler = async (id: number | string) => {
    updateDeliveryAddress({
      requestId: data?.id,
      addressId: id,
    })
      .then((res) => {
        onSubmitNote('');
        handleModalClose();
      })
      .catch((er) => {
        toast.error("You can't change address!");
        console.log('Error on updating:', er);
      });
  };

  function modalHandler() {
    setDeliveryAddressId(data?.delivery_address.id);
    setModalOpen(true);
  }
  const handleModalClose = () => setModalOpen(false);

  const onSelectPlace = (placeDetail: TypeGooglePlaceDetail) => {
    setValue('latitude', placeDetail.lat);
    setValue('longitude', placeDetail.lon);
    setValue('zipcode', placeDetail.zipcode);
    setValue('address_line_1', placeDetail.addressLine1);
    setValue('city', placeDetail.city);
    setValue('state', placeDetail.state);
  };

  const selectedCard = cards.find((item) => item.custom_uuid === cardId);

  if (!data) return <Spinner />;

  return (
    <div>
      <Header
        id={id}
        drugId={data.prescriptions[0].id}
        created_at={data.created_at}
        status={data.prescriptions[0].status}
        onChangeStatus={(newVal: any, action: any) => {
          onChangeScriptStatus(newVal, action);
        }}
        origin={data.request_origin}
        app={data.request_origin}
        completeDisabled={markDisabled}
        complete={complete}
        previous_id={data.previous_id}
        next_id={data.next_id}
      />

      <div className={styles.content}>
        <div className={styles.main_content}>
          <CustomerDetails
            pharmacy={data.pharmacy}
            customer={data.customer}
            deliveryData={data?.delivery_address}
            modalHandler={modalHandler}
          />
          <Card>
            <CardContent dark>
              <NoteForm onSubmit={onSubmitNote} />
              {data.notes.map((note, index) => (
                <Note
                  key={note.id}
                  {...note}
                  text={note.text}
                  isCompleted={
                    get(data, 'status', '') === 'completed' &&
                    index === 0 &&
                    !!get(data, 'route_id', null)
                  }
                  route_id={Number(get(data, 'route_id', null))}
                />
              ))}
            </CardContent>
          </Card>
        </div>

        <div className={styles.drugs}>
          {!(data.request_origin === 'app') && (
            <PreferenceTime
              onChangeTime={onChangeTime}
              priorities={priority}
              priority={data.priority}
              onChangePriority={onChangePriority}
              preference_time={data.preference_time}
              preference_date={data.preference_date}
            />
          )}
          {data.prescriptions.length > 0 ? (
            <Prescriptions
              requestId={data.id}
              origin={data.request_origin}
              drugs={data.prescriptions}
              status={data.status}
              onChangeStatus={(newVal, action) => {
                onChangeScriptStatus(newVal, action);
              }}
              sync_source={data.sync_source}
              onCharge={onCharge}
              onSendNotification={handleNotifyCustomer}
              onRequestSignature={handleRequestSignature}
            />
          ) : (
            ''
          )}
          <Otcs
            requestId={data.id}
            drugs={data.otcs}
            origin={data.request_origin}
            onChangeStatus={onChangeScriptStatus}
          />
        </div>
      </div>
      <Modal
        isOpen={modalOpen}
        onClose={handleModalClose}
        className={styles.list_wrapper}
      >
        <Typography component="p" className={styles.modalDesc} variant="h2">
          Select Delivery Address:
        </Typography>
        <div className={styles.optionWrapper}>
          {get(data.customer, 'delivery_addresses', []).map(
            (item: any, index: Key | null | undefined) => {
              return (
                <label key={index} className={styles.container}>
                  <input
                    type="radio"
                    name="radio"
                    defaultChecked={deliveryAddressId === item.id}
                    onClick={() => setDeliveryAddressId(item.id)}
                  />
                  <span className={styles.radioButtonText}>
                    {get(item, 'address_line_1', '')}, {get(item, 'city', '')},{' '}
                    {get(item, 'state', '')}, {get(item, 'zipcode', '')}
                    {get(item, 'unit_address', '')
                      ? `, ${get(item, 'unit_address', '')}`
                      : ''}
                  </span>
                  {!get(item, 'is_valid', true) && (
                    <span className={styles.wrongAddress}>issue</span>
                  )}
                </label>
              );
            }
          )}
          <div
            onClick={() => setOpenAddressModal(true)}
            className={styles.addAddress}
          >
            + Add address
          </div>
        </div>
        <div className={styles.actions}>
          <Button color="outlined" size="md" onClick={handleModalClose}>
            Close
          </Button>
          <Button
            type="submit"
            color="green"
            onClick={() => addressChangeHandler(deliveryAddressId || 0)}
            size="md"
            loading={addressIsUpdating}
          >
            Submit
          </Button>
        </div>
      </Modal>
      {data.insurance &&
        data.insurance.front_image &&
        data.insurance.back_image && (
          <ImageView
            show={showInsurance}
            onClose={() => setShowInsurance(false)}
            images={[
              data.insurance.front_image.file,
              data.insurance.back_image.file,
            ]}
          />
        )}
      <Modal
        isOpen={openAddressModal}
        onClose={() => setOpenAddressModal(false)}
        className={styles.list_wrapper}
      >
        <div className={styles.modalWrapper}>
          <FormGroup>
            <Controller
              name="city"
              control={control}
              rules={{required: 'Please, Select the address'}}
              render={({field, fieldState}) => (
                <GooglePlaceAutoComplete
                  label="Your address"
                  placeholder="Address"
                  onSelectPlace={onSelectPlace}
                  error={fieldState.error?.message}
                  options={{
                    types: ['address'],
                  }}
                />
              )}
            />
            <Controller
              name="unit_address"
              control={control}
              render={({field, fieldState}) => (
                <TextField
                  label="Unit number"
                  wrapperClass={styles.googleInput}
                  placeholder="Enter unit number"
                  hasError={false}
                  onChange={field.onChange}
                  error={fieldState.error?.message}
                />
              )}
            />
          </FormGroup>

          <div className={styles.actionWrapper}>
            <Button
              className={styles.cancelButton}
              onClick={() => {
                setOpenAddressModal(false);
              }}
              component="a"
              color="gray"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={handleSubmit(handleCreation)}
              color="green"
            >
              Add
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={selectCardModal}
        onClose={() => setSelectCardModal(false)}
        className={styles.list_wrapper}
      >
        <Typography component="p" className={styles.modalDesc} variant="h2">
          Select Card to Charge:
        </Typography>
        <div className={styles.optionWrapper}>
          {cards.map((item: any, index: Key | null | undefined) => {
            return (
              <label key={index} className={styles.radioOption}>
                <input
                  type="radio"
                  name="radio"
                  defaultChecked={cardId === item.custom_uuid}
                  onClick={() => setCardIs(item.custom_uuid)}
                />
                {/* <div className={styles.column}> */}
                <span className={styles.radioButtonText}>
                  {get(item, 'cardholder_name', '')}
                </span>
                <p
                  style={{marginLeft: 'auto'}}
                  className={styles.radioButtonText}
                >
                  **** **** **** {get(item, 'last4', '')}
                </p>
                {/* </div> */}
              </label>
            );
          })}
        </div>
        <div className={styles.actions}>
          <Button
            color="outlined"
            size="md"
            onClick={() => setSelectCardModal(false)}
          >
            Close
          </Button>
          <Button
            type="submit"
            color="green"
            onClick={() => setAccessCardModal(true)}
            size="md"
            loading={addressIsUpdating}
          >
            Submit
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={accessCardModal}
        onClose={() => setAccessCardModal(false)}
        className={styles.list_wrapper}
      >
        <Typography component="p" className={styles.modalDesc} variant="h2">
          Are you sure this card to charge:
        </Typography>
        <div className={styles.card}>
          <div className={styles.item_header}>
            <div>{get(selectedCard, 'cardholder_name', '')}</div>
          </div>
          <div className={styles.row}>
            <div>**** **** **** {get(selectedCard, 'last4', '')}</div>
            <div>
              Expiration: {get(selectedCard, 'exp_month', '')}/
              {get(selectedCard, 'exp_year', '')}
            </div>
          </div>
        </div>
        <div className={styles.actions}>
          <Button
            color="outlined"
            size="md"
            onClick={() => setAccessCardModal(false)}
          >
            Close
          </Button>
          <Button
            type="submit"
            color="green"
            onClick={submitSelectedCard}
            size="md"
            loading={addressIsUpdating}
          >
            Submit
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default RequestDetails;
