import Button from 'components/Button';
import DataTable from 'components/DataTable';
import ListActionsWrapper from 'components/ListActionsWrapper';
import ListWrapper from 'components/ListWrapper';
import SearchInput from 'components/SearchInput';
import PageHeader from 'components/PageHeader';
import Typography from 'components/Typography';
import Filter from 'components/Filter';

import useEmployees from './useEmployees';

import styles from '../Settings.module.scss';
import {DataItem, DataTableSchemaItem} from 'types';
import {useCallback} from 'react';
import {useHistory} from 'react-router-dom';

const SCHEMA: DataTableSchemaItem[] = [
  {
    dataKey: 'name',
    header: 'Employee name',
    sortable: true,
  },
  {
    dataKey: 'phone',
    header: 'Phone number',
    sortable: true,
  },
  {
    dataKey: 'role',
    header: 'Employee status',
    sortable: true,
  },
  {dataKey: 'view', header: ''},
];

function Employees() {
  const {
    data,
    isLoading,
    filterOptions,
    filterValue,
    sortBy,
    sortOrder,
    onChangeFilter,
    onSort,
    keyWord,
    setKeyWord,
  } = useEmployees();

  const history = useHistory();

  const handleNavigateEmployee = useCallback(
    (row: DataItem) => {
      history.push(`/settings/employees/${row.id}`);
    },
    [history]
  );

  return (
    <div className={styles.main_container}>
      <PageHeader>
        <Typography variant="h2">Employees</Typography>
        <Button to="/settings/employees/new" component="a" color="green">
          New Employee
        </Button>
      </PageHeader>

      <ListWrapper>
        <ListActionsWrapper className={styles.list_header}>
          <SearchInput
            value={keyWord}
            onChange={(val) => {
              setKeyWord(val.target.value);
              sessionStorage.setItem(`search_employe`, val.target.value);
            }}
            size="sm"
          />
          <Filter
            size="sm"
            options={filterOptions}
            value={filterValue}
            onChange={onChangeFilter}
          />
        </ListActionsWrapper>
        <DataTable
          sortBy={sortBy}
          sortOrder={sortOrder}
          onSort={onSort}
          selectable={false}
          data={data}
          schema={SCHEMA}
          loading={isLoading}
          onClick={handleNavigateEmployee}
        />
      </ListWrapper>
    </div>
  );
}

export default Employees;
