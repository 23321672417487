import {useRouteMatch, Switch, Route, Redirect} from 'react-router-dom';

import Users from './Users';
import Employees from './Employees';
import Gateway from './PaymentGateway';
import Vehicles from './Vehicles';
import Routes from './Routes';

import styles from './Settings.module.scss';
import CompanyDetails from 'pages/CompanyDetails';

function Settings() {
  const {path} = useRouteMatch();

  return (
    <div className={styles.root}>
      <Switch>
        <Route path={`${path}/users`} component={Users} />
        <Route path={`${path}/employees`} component={Employees} />
        <Route path={`${path}/gateway`} component={Gateway} />
        <Route path={`${path}/vehicles`} component={Vehicles} />
        <Route path={`${path}/routes`} component={Routes} />
        <Route path={`${path}/company`} component={CompanyDetails} />
        <Redirect to={`${path}/users`} />
      </Switch>
    </div>
  );
}

export default Settings;
