import {useHistory} from 'react-router';

import SearchInput from 'components/SearchInput';
import ListTabs from 'components/ListTabs';
import ListWrapper from 'components/ListWrapper';
import Typography from 'components/Typography';
import ListActionsWrapper from 'components/ListActionsWrapper';
import DataTable from 'components/DataTable';
import {Address, Customer, DataItem, ListResponse, Product} from 'types';
import styles from './Requests.module.scss';
import stat_time from '../DeliveryPlanner/icons/stat_time.svg';
import calendar from '../DeliveryPlanner/icons/calendar.svg';
import {DataTableSchemaItem} from 'types';
import useRequests from './useRequests';
import {useCallback, useEffect, useState} from 'react';
import Pagination from 'components/Pagination';
import {useRouteMatch} from 'react-router-dom';
import DateTimePicker from 'components/DateTimePicker';
import Select from 'components/Select';
import {components} from 'react-select';
import {groupBy, isArray, map, reject, sortBy as sortLodash} from 'lodash';
import nyPostalCodeBoroughOptions from 'shared/ny-postal-code-borough-options';
import locationIcon from './location.svg';
import Button from 'components/Button';
import Modal from 'components/Modal';
import api from 'api';
import toast from 'react-hot-toast';
import {CreateAddress} from 'pages/UserForm/UserAddresses/UserAddresses';
import {addressToString} from 'helpers/address';
import {NewProduct} from 'pages/Products/Products';
import {NewCustomer} from 'pages/Settings/Users/Users';
import classNames from 'classnames';

const SCHEMA: DataTableSchemaItem[] = [
  {dataKey: 'number', header: 'Request #', sortable: false},
  // {dataKey: 'rx_no', header: 'Rx #', sortable: false},
  {dataKey: 'sync_time', header: 'Sync time', sortable: true},
  {dataKey: 'customer', header: 'Customer', sortable: true},
  {dataKey: 'status', header: 'Status', sortable: true},
  {dataKey: 'drug_name', header: 'Drug name', sortable: false},
  // {dataKey: 'refill_no', header: 'Refill #', sortable: false},
];
const MM_SCHEMA: DataTableSchemaItem[] = [
  // {dataKey: 'rx_no', header: 'Rx #', sortable: true, index: 0},
  {
    dataKey: 'number',
    header: 'Order #',
    sortable: true,
    // align: 'center',
    index: 0,
  },
  {
    dataKey: 'customer',
    header: 'Customer',
    sortable: true,
    index: 1,
  },
  {dataKey: 'status', header: 'Status', sortable: true, index: 2},
  {dataKey: 'offering', header: 'Offering', sortable: false, index: 3},
  {dataKey: 'amount', header: 'Amount', sortable: false, index: 4},
  // {
  //   dataKey: 'number',
  //   header: 'Order #',
  //   sortable: true,
  //   align: 'center',
  //   index: 5,
  // },
  // {dataKey: 'refill_no', header: 'Refill #', sortable: false, index: 6},
  {
    dataKey: 'sync_time',
    header: 'Date/Time',
    sortable: true,
    align: 'center',
    index: 7,
  },
  {
    dataKey: 'priority',
    header: 'Priority',
    sortable: true,
    align: 'center',
    index: 7,
  },
  {
    dataKey: 'preference_date',
    header: 'Preference date',
    sortable: false,
    index: 8,
  },
  // {dataKey: 'view', header: '', align: 'right'},
];

const locationOptionsGrouped = groupBy(nyPostalCodeBoroughOptions, 'label');
const sorted: {label: string; value: any}[] = map(
  locationOptionsGrouped,
  (obj, key) => ({
    label: key,
    value: map(obj, 'value'),
  })
);
const locationOptions = sortLodash(sorted, 'label');

type Props = {
  source: string;
};

function Requests({source}: Props) {
  const {params} = useRouteMatch<{page: string | undefined}>();
  const [currentPage, setCurrentPage] = useState(parseInt(params.page || '1'));
  const {
    data,
    totalPages,
    dailyCount,
    loading,
    sortBy,
    sortOrder,
    tabs,
    status,
    onSort,
    setStatus,
    keyWord,
    setKeyWord,
    MMtabs,
    onChangeData,
    onChangeLocation,
    date,
    location,
    setPreferenceTime,
    preferenceTime,
    setOnlyOTC,
    onlyOTC,
    refetch,
    paid,
    setPaid,
    priority,
    setPriority,
  } = useRequests({page: currentPage - 1, source});
  const history = useHistory();

  const [visible, setVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [clients, setClients] = useState<Customer[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [productIds, setProductIds] = useState<any>([]);
  const [userId, setUserId] = useState<any>(0);
  const [addressId, setAddressId] = useState<any>(0);
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [addressVisible, setAddressVisible] = useState(false);
  const [productVisible, setProductVisible] = useState(false);

  const [MMTable, setMMTable] = useState(
    MM_SCHEMA.map((item) => ({
      ...item,
      value: item.dataKey,
      label: item.header,
    }))
  );

  const getLocalTable = () => {
    let localTable = localStorage.getItem('tableData');
    if (localTable) {
      const data: any[] = MM_SCHEMA.filter((item) =>
        JSON.parse(localTable || '').find(
          (el: any) => el.dataKey === item.dataKey
        )
      );
      setMMTable(
        data.map((item) => ({...item, value: item.dataKey, label: item.header}))
      );
    }
  };

  useEffect(() => {
    getLocalTable();
    getClients(1, '');
    getProducts(1, '');
  }, []);

  const getClients = async (page: number, keyWord: string) => {
    try {
      const {data} = await api.get<ListResponse<Customer>>(`/customers/`, {
        params: {
          limit: 150,
          search: keyWord,
        },
      });
      setClients(data.results);
    } catch (error) {}
  };

  const getProducts = async (page: number, keyWord: string) => {
    try {
      const {data} = await api.get<ListResponse<Product>>(`/products/`, {
        params: {
          limit: 150,
          search: keyWord,
        },
      });
      setProducts(data.results);
    } catch (error) {}
  };

  const getAddress = async (id: number) => {
    setAddresses([]);
    try {
      const {data} = await api.get<Customer>(`/customers/${id}/`);
      setAddresses(data.addresses || []);
    } catch (error) {}
  };

  const handleNavigateRequest = useCallback(
    (row: DataItem) => {
      if (source === 'app') {
        history.push(`/requests/${row.id}`);
      } else {
        history.push(`/orders/${row.id}`);
      }
    },
    [history, source]
  );

  useEffect(() => {
    if (params.page) {
      setCurrentPage(parseInt(params.page));
    }
  }, [params.page]);

  const onChangeTab = (val: any) => {
    if (val) {
      const newTabs = MMTable.some((item) => item.label === val.label)
        ? reject(MMTable, val)
        : [...MMTable, val];

      setMMTable(sortLodash(newTabs, 'index'));

      localStorage.setItem(
        'tableData',
        JSON.stringify(sortLodash(newTabs, 'index'))
      );
    }
  };

  const onSubmit = () => {
    setSubmitting(true);
    api
      .post(source === 'app' ? 'requests/create/' : 'requests/create-order/', {
        customer_id: userId,
        address_id: addressId,
        drugs: productIds,
      })
      .then(() => {
        refetch();
        setUserId(0);
        setAddressId(0);
        setProductIds([]);
        setVisible(false);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <div className={styles.top_header}>
        <SearchInput
          className={styles.input}
          value={keyWord}
          onChange={(val) => {
            setKeyWord(val.target.value);
            setCurrentPage(1);
            if (params.page !== '1') {
              if (source === 'app') {
                history.push('/requests/page/1');
              } else {
                history.push('/orders/page/1');
              }
            }
            sessionStorage.setItem(`searchKey_${source}`, val.target.value);
          }}
          placeholder="Search"
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            gap: 6,
          }}
        >
          <Button onClick={() => setVisible(true)} color="blue">
            {source === 'app' ? 'Add request' : 'Add Order'}
          </Button>
          <Typography variant="h4">
            {source === 'app' ? 'Today’s App Requests' : 'Today’s Orders :'}{' '}
            {dailyCount}
          </Typography>
        </div>
      </div>
      <ListWrapper>
        <ListTabs
          onChange={setStatus}
          isSupportPage={false}
          activeTabValue={status}
          tabs={source === 'micromerchant' ? MMtabs : tabs}
        />
        <ListActionsWrapper>
          <div className={styles.row}>
            <Select
              className={'DeliveryPlanner__Filter'}
              placeholder="Location"
              components={{
                Input: () => null,
                DropdownIndicator: (props) =>
                  !location ? (
                    <components.DropdownIndicator {...props} />
                  ) : null,
                Control: ({children, ...props}) => {
                  return (
                    <components.Control {...props}>
                      <img
                        className={styles.locationIcon}
                        src={locationIcon}
                        alt="location_icon"
                      />
                      {children}
                    </components.Control>
                  );
                },
                SingleValue: ({children, ...props}) => {
                  return (
                    <components.SingleValue {...props}>
                      {isArray(props.selectProps.value)
                        ? props.selectProps.value
                            .map((item: {label: string}) => item.label)
                            .join(', ')
                        : ''}
                    </components.SingleValue>
                  );
                },
                IndicatorSeparator: () => null,
              }}
              isSearchable={false}
              onChange={onChangeLocation}
              isClearable
              options={[
                {
                  label: 'Locations',
                  options: locationOptions,
                },
              ]}
              value={location}
            />
            {source === 'micromerchant' && (
              <Select
                className={styles.selectCon}
                isSearchable={false}
                onChange={(val: any) => setOnlyOTC(val)}
                options={[
                  {label: 'Service', value: 'service'},
                  {label: 'Product', value: 'product'},
                ]}
                value={onlyOTC}
                placeholder={
                  <span className="select-placeholder-text">
                    Service/Product
                  </span>
                }
                isClearable
                components={{
                  Input: () => null,
                  DropdownIndicator: (props) =>
                    !onlyOTC ? (
                      <components.DropdownIndicator {...props} />
                    ) : null,
                  IndicatorSeparator: () => null,
                }}
              />
            )}
            {source === 'micromerchant' && (
              <Select
                className={styles.selectCon}
                isSearchable={false}
                onChange={(val: any) => setPaid(val)}
                options={[
                  {label: 'Paid', value: 'true'},
                  {label: 'Unpaid', value: 'false'},
                ]}
                value={paid}
                placeholder={
                  <span className="select-placeholder-text">Paid/Unpaid</span>
                }
                isClearable
                components={{
                  Input: () => null,
                  DropdownIndicator: (props) =>
                    !paid ? <components.DropdownIndicator {...props} /> : null,
                  IndicatorSeparator: () => null,
                }}
              />
            )}
          </div>
          {source === 'micromerchant' && (
            <div className={styles.preferenceDate}>
              <Select
                className={classNames(styles.selectCon, styles.priority)}
                isSearchable={false}
                onChange={(val: any) => setPriority(val)}
                options={[
                  {label: 'High', value: 'high'},
                  {label: 'Medium', value: 'medium'},
                  {label: 'Low', value: 'low'},
                ]}
                value={priority}
                placeholder={
                  <span className="select-placeholder-text">Priority</span>
                }
                isClearable
                components={{
                  Input: () => null,
                  DropdownIndicator: (props) =>
                    !priority ? (
                      <components.DropdownIndicator {...props} />
                    ) : null,
                  IndicatorSeparator: () => null,
                }}
              />
              <DateTimePicker
                icon={calendar}
                showTimeSelect={false}
                dateFormat="MM/dd/yy E"
                value={date}
                placeholder="Pr. date"
                customInput={styles.customInput}
                onChange={onChangeData}
              />
              <Select
                className={styles.selectCon}
                isSearchable={false}
                onChange={(val: any) => setPreferenceTime(val)}
                options={[
                  {label: 'Morning', value: 'morning'},
                  {label: 'Afternoon', value: 'afternoon'},
                  {label: 'Evening', value: 'evening'},
                ]}
                value={preferenceTime}
                placeholder={
                  <span className="select-placeholder-text">Pr. time</span>
                }
                isClearable
                components={{
                  Input: () => null,
                  DropdownIndicator: (props) =>
                    !preferenceTime ? (
                      <components.DropdownIndicator {...props} />
                    ) : null,
                  Control: ({children, ...props}) => (
                    <components.Control {...props} className="select-row">
                      <img className={'timer'} src={stat_time} alt="timer" />
                      {children}
                    </components.Control>
                  ),

                  IndicatorSeparator: () => null,
                }}
              />
              <Select
                className={'Table_Select'}
                isSearchable={false}
                onChange={onChangeTab}
                options={[
                  {
                    label: 'Table column',
                    options: MM_SCHEMA.map((item) => ({
                      ...item,
                      value: item.dataKey,
                      label: item.header,
                    })),
                  },
                ]}
                value={MMTable}
                placeholder={
                  <span className="select-placeholder-text">Display</span>
                }
                components={{
                  Input: () => null,
                  DropdownIndicator: (props) =>
                    !preferenceTime ? (
                      <components.DropdownIndicator {...props} />
                    ) : null,
                  SingleValue: ({children, ...props}) => {
                    return (
                      <components.SingleValue {...props}>
                        Display
                      </components.SingleValue>
                    );
                  },
                  IndicatorSeparator: () => null,
                }}
                closeMenuOnSelect={false}
              />
            </div>
          )}
        </ListActionsWrapper>
        <DataTable
          schema={source === 'app' ? SCHEMA : MMTable}
          data={data}
          sortBy={sortBy}
          sortOrder={sortOrder}
          onClick={handleNavigateRequest}
          onSort={onSort}
          loading={loading}
        />
        {!loading && (
          <Pagination
            basePath={source === 'app' ? '/requests' : '/orders'}
            page={currentPage}
            total={totalPages}
            setCurrentPage={setCurrentPage}
          />
        )}
      </ListWrapper>
      <Modal
        isOpen={visible}
        onClose={() => setVisible(false)}
        className={styles.list_wrapper}
        onRequestClose={() => setVisible(false)}
      >
        <Typography variant="h2">
          Add new {source === 'app' ? 'request' : 'order'}
        </Typography>
        <div onClick={() => setModalOpen(true)} className={styles.label}>
          Customer +
        </div>
        <Select
          // label="Customer:"
          placeholder="Click to select"
          onChange={(item) => {
            setUserId(item?.value);
            getAddress(Number(item?.value));
          }}
          value={
            clients.find((item) => item.id === userId)
              ? {
                  value:
                    clients.find((item) => item.id === userId)?.user?.id || '',
                  label:
                    clients.find((item) => item.id === userId)?.user
                      ?.first_name +
                    ' ' +
                    clients.find((item) => item.id === userId)?.user?.last_name,
                }
              : undefined
          }
          menuPosition="fixed"
          options={clients.map((item) => ({
            value: item.id,
            label: item.user.first_name + ' ' + item.user.last_name,
          }))}
        />
        {userId !== 0 && (
          <>
            <div
              onClick={() => setAddressVisible(true)}
              className={styles.label}
            >
              Address +
            </div>

            <Select
              placeholder="Click to select"
              menuPosition="fixed"
              onChange={(item) => {
                setAddressId(item?.value);
              }}
              value={
                addresses.find((item) => item.id === addressId)
                  ? {
                      value:
                        addresses.find((item) => item.id === addressId)?.id ||
                        '',
                      label: addressToString(
                        addresses.find(
                          (item) => item.id === addressId
                        ) as Address
                      ),
                    }
                  : undefined
              }
              options={addresses.map((item) => ({
                value: item.id,
                label:
                  item.address_line_1 +
                  ',' +
                  item.city +
                  ',' +
                  item.state +
                  ',' +
                  item.zipcode,
              }))}
            />
          </>
        )}
        <div onClick={() => setProductVisible(true)} className={styles.label}>
          Product +
        </div>
        <Select
          placeholder="Click to select"
          menuPosition="fixed"
          onChange={(item) => {
            if (isArray(item)) {
              setProductIds(item.map((item) => item.value));
            } else {
              setProductIds([item?.value]);
            }
          }}
          value={
            productIds.length > 0
              ? products
                  .filter((item) => productIds.includes(item.id))
                  .map((item) => {
                    return {
                      value: item.id,
                      label: item.name,
                    };
                  })
              : undefined
          }
          isSearchable={true}
          //@ts-ignore
          isMulti={true}
          options={products.map((item) => ({
            value: item.id,
            label: item.name,
          }))}
        />

        <Button
          loading={submitting}
          disabled={!addressId || !userId || !productIds.length}
          className={styles.addBtn}
          onClick={onSubmit}
          color="blue"
        >
          {source === 'app' ? 'Add request' : 'Add Order'}
        </Button>
        <NewCustomer
          onSuccess={(data) => {
            setClients((pr) => [data, ...pr]);
            setUserId(data.id);
          }}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />

        <CreateAddress
          customer_id={userId}
          handleClose={() => setAddressVisible(false)}
          onSuccess={(data) => {
            setAddresses((pr) => [data, ...pr]);
            setAddressId(data.id);
          }}
          isOpen={addressVisible}
        />
        <NewProduct
          visible={productVisible}
          onClose={() => setProductVisible(false)}
          onSuccess={(data) => {
            setProducts((pr) => [data, ...pr]);
            setProductIds([data.id]);
          }}
        />
      </Modal>
    </>
  );
}

export default Requests;
